.home {
  width: 100%;
  text-align: center;
}
.home h2 {
  font-size: 3.5em;
  margin-top: 100px;
  margin-bottom: 10px;
  color: #0f6938;
}

.home .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px;
}

.home .side-text {
  font-size: 2em;
  color: white;
  padding: 20px;
  width: 40%;
  min-height: 40%;
  text-align: justify;
}

.home .side-text.arab {
  direction: rtl;
  font-size: 2.25em;
}

.home .hidden {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s linear, transform 0.5s linear;
}

.home .show {
  opacity: 1;
  transform: translateX(0);
}

.star {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scale: 0.2;
  /* mix-blend-mode: screen; */
  transition: all 0.2 linear;
  z-index: 9000;
}

.header-margin {
  margin-top: 100px;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .home .side-text {
    font-size: 1em;
  }
  .home .side-text.arab {
    font-size: 1.3em;
  }

  .home .section {
    margin-bottom: 30px;
  }
}

@media (max-width: 650px) {
  .header-margin {
    margin-top: 200px;
  }
}

@media (max-width: 525px) {
  .home .section {
    flex-direction: column;
  }
  .home .side-text {
    width: 100%;
  }

  .home .side-text.arab {
    display: none;
    padding: 0px;
  }

  .home .section {
    padding-left: 10%;
    padding-right: 10%;
  }
}
