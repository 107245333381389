.cards-container {
  display: grid;
  width: 100%;
  grid-template-columns: 460px 460px;
  justify-content: center;
  column-gap: 15%;
  padding: 0px 50px;
  padding-bottom: 200px;
}

.card {
  cursor: pointer;
  height: 400px;
  min-height: 400px;
  perspective: 1000px;
  margin: 1rem;
  position: relative;
}

.front,
.back {
  display: flex;
  border-radius: 6px;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
}

.front {
  background-size: cover;
  padding: 2rem;
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
}

.card:hover .front {
  transform: rotateY(180deg);
}
.card:nth-child(even):hover .front {
  transform: rotateY(-180deg);
}

.back {
  background: #0f6938;
  transform: rotateY(-180deg);
  padding: 0 2em;
}

.card:hover .back {
  transform: rotateY(0deg);
}
.card:nth-child(even) .back {
  transform: rotateY(180deg);
}

.card:nth-child(even):hover .back {
  transform: rotateY(0deg);
}

.text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text h3 {
  font-size: 2em;
  font-weight: 600;
  width: 100%;
  color: #d9e127;
  padding: 15 px;
}

.text p {
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  text-align: justify;
}

.text .arab p {
  text-align: right;
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 500px;
  }
}

@media (max-width: 526px) {
  .cards-container {
    grid-template-columns: 420px;
  }
  .text p {
    font-size: 0.9em;
  }
}
