.goal {
  width: 100%;
}

.goal p {
  color: white;
  font-size: 1.5em;
  text-align: justify;
  pointer-events: none;
}

.goal .arab {
  direction: rtl;
}

.goal p strong {
  color: #d9e127;
  /* color: #0f6938; */
}

.goal .hidden {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s linear, transform 0.5s linear;
}

.goal .show {
  opacity: 1;
  transform: translateX(0);
}

.goal img {
  width: 100%;
}

@media (max-width: 650px) {
  .goal p {
    font-size: 1.2em;
  }
}
