footer {
  position: relative;
  width: 100%;
  background-color: #01502c;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .social-icons,
.menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

footer .social-icons li,
footer .menu li {
  list-style: none;
}

footer .social-icons li a,
footer .menu li a {
  font-size: 2em;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  cursor: pointer;
}

footer .social-icons li a:hover,
footer .menu li a:hover {
  transform: translateY(-10px);
}

footer .menu li a {
  color: #fff;
  font-size: 1.2em;
  margin: 0 10px;
  padding-bottom: 20px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
}

footer .menu li a:hover {
  opacity: 1;
}

footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(../../../public/pictures/wave.png);
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}

footer .wave#wave2 {
  z-index: 999;
  opacity: 0.4;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}
footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
}

footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}
