.about {
  margin: 0;
  padding: 0px 100px;
}
.about h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #0f6938;
  text-align: center;
  padding-bottom: 100px;
}

.about .field {
  display: flex;
  gap: 20px;
  padding-bottom: 150px;
}

.about .field .caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about p {
  color: white;
  font-size: 1.5em;
  text-align: justify;
}

.about .ar {
  direction: rtl;
}

.certificates {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 25%);
  gap: 10px;
  justify-content: center;
  padding-bottom: 200px;
}

.team {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 25%);
  gap: 10px;
  justify-content: center;
  padding-bottom: 200px;
}

.slider-wrapper {
  margin-bottom: 200px;
}

.clients {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 300px;
}

.clients ul li {
  font-size: 1.8rem;
  color: white;
}

.arab li {
  text-align: right;
  direction: rtl;
}

.values {
  padding-bottom: 100px;
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px) {
  .about .field {
    display: flex;
    flex-direction: column;
  }
  .certificates,
  .team {
    grid-template-columns: repeat(1, 350px);
  }

  .clients ul {
    padding-left: 40px;
  }

  .clients ul li {
    font-size: 1.5rem;
  }
  .clients .arab {
    display: none;
  }
}

@media (max-width: 526px) {
  .about {
    padding: 0px 20px;
  }

  .about .ar {
    display: none;
  }

  .values {
    grid-template-columns: repeat(1, 100%);
  }
}
