.form-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.big-form-modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  outline: none;
}

.big-form-modal-wrapper img {
  width: 800px;
  height: 800px;
}

.button-no-styles {
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 20px;
}

@media (max-width: 768px) {
  .big-form-modal-wrapper {
    width: 100%;
    height: 100%;
  }
}
