.certification {
  position: relative;
  height: 350px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.certification::before {
  content: "";
  position: absolute;
  inset: -10px 140px;
  background: linear-gradient(315deg, #15ff00, #d4c600);
  transition: 0.5s;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}

.certification:hover::before {
  inset: -20px;
}

.certification::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #0f6938;
  border-radius: 50%;
  z-index: 1;
}

.certification .content {
  position: absolute;
  inset: 20px;
  z-index: 3;
  border-radius: 50%;
  overflow: hidden;
}

.certification .certification-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
