.value {
  --c: white; /* the color */
  --b: 0.1em; /* border length*/
  --d: 20px; /* the cube depth */
  border-radius: 20px;
  padding: 10px;
  --_s: calc(var(--d) + var(--b));

  color: var(--c);
  border: solid #2fb462;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background: conic-gradient(
        at left var(--d) bottom var(--d),
        #0000 90deg,
        rgb(255 255 255 /0.3) 0 225deg,
        rgb(255 255 255 /0.6) 0
      )
      border-box,
    conic-gradient(at left var(--_s) bottom var(--_s), #0000 90deg, var(--c) 0)
      0 100% / calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  transform: translate(calc(var(--d) / -1), var(--d));
  clip-path: polygon(
    var(--d) 0%,
    var(--d) 0%,
    100% 0%,
    100% calc(100% - var(--d)),
    100% calc(100% - var(--d)),
    var(--d) calc(100% - var(--d))
  );
  transition: 0.5s;
}
.value:hover {
  transform: translate(0, 0);
  clip-path: polygon(
    0% var(--d),
    var(--d) 0%,
    100% 0%,
    100% calc(100% - var(--d)),
    calc(100% - var(--d)) 100%,
    0% 100%
  );
}

.value h3 {
  color: #2fb462;
  color: #d4c600;
}

.value .arab p,
.value .arab h3 {
  direction: rtl;
}

@media (max-width: 769px) {
  .value .arab {
    display: none;
  }
}
