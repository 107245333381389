.people {
  position: relative;
  height: 350px;
  border-radius: 50%;
  overflow: hidden;
}
/* 
.people:hover h2 {
} */

.people::before {
  content: "";
  position: absolute;
  inset: -10px 140px;
  background: linear-gradient(315deg, #15ff00, #d4c600);
  transition: 0.5s;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}

.people:hover::before {
  inset: -20px;
}

.people::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #0f6938;
  border-radius: 50%;
  z-index: 1;
}

.people .content {
  position: absolute;
  inset: 20px;
  z-index: 3;
  border-radius: 50%;
  overflow: hidden;
}

.people .people-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 5; */
}

.people .content h2 {
  position: absolute;
  top: 100px;
  left: 20px;
  color: #0f6938;
  /* color: white; */
  opacity: 0;
  transition: all 1s linear;
  font-size: 1.4rem;
  text-align: center;
  width: 80%;
}

.people .content h2 strong {
  color: #d4c600;
  color: black;
  color: white;
}

.people .content h2 strong::before {
  content: "\a";
  white-space: pre;
}

.people:hover h2 {
  opacity: 1;
}
