.box {
  position: relative;
  width: 600px;
  height: 800px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 100px;
}

.box-small {
  height: 600px;
}

.box::before {
  content: "";
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(0deg, transparent, #3cb64b, #3cb64b);
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}
.box::after {
  content: "";
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(0deg, transparent, #3cb64b, #3cb64b);
  top: -50%;
  left: -50%;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form {
  position: absolute;
  z-index: 10;
  background: white;
  inset: 5px;
  border-radius: 8px;
  padding: 5px 40px;
  display: flex;
  flex-direction: column;
}

.input-box {
  position: relative;
  width: 100%;
  margin-top: 35px;
}

.input-box input {
  position: relative;
  width: 100%;
  padding: 40px 10px 10px;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.input-box select {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.input-box select option {
  border: none;
}

.input-box span {
  position: absolute;
  left: 0;
  padding: 40px 0px 10px;
  font-size: 1em;
  color: black;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.input-box a {
  position: absolute;
  top: 40px;
  right: 0px;
  color: red;
  font-size: 1em;
  font-family: "Courier New", Courier, monospace;
  z-index: 20;
}

.input-box input:valid ~ span,
.input-box input:focus ~ span {
  color: #3cb64b;
  transform: translateY(-34px);
  font-size: 0.75em;
}

.input-box i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #3cb64b;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.input-box i.select {
  top: 70px;
}

.input-box input:valid ~ i,
.input-box input:focus ~ i {
  height: 44px;
}

.form button {
  height: 50px;
  border: none;
  outline: none;
  background: #3cb64b;
  width: 100%;
  margin-top: 50px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  color: white;
}

.form button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 526px) {
  .box {
    width: 400px;
  }
}
@media (max-width: 376px) {
  .box {
    width: 350px;
  }
}
