.contact {
  margin: 0;
  padding: 0px 100px;
  padding-bottom: 200px;
}

.map {
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
  overflow: hidden;
}
.map iframe {
  width: 100%;
  height: 100%;
}

.contact h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #0f6938;
  text-align: center;
}

.contact .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact .section div,
.contact .section h3 {
  color: white;
}

.contact .section .text {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: larger;
}

.contact .section .text .number {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
}

.form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 0px;
}

@media (max-width: 526px) {
  .contact {
    padding: 0px 50px;
  }
  .contact .section {
    flex-direction: column;
    align-items: center;
  }

  .contact h2 {
    font-size: 2em;
  }

  .contact .section .text {
    width: 100%;
    margin-bottom: 50px;
  }

  .contact .section .text .number {
    justify-content: center;
    gap: 40px;
  }

  .contact .section h3 {
    font-size: 1em;
  }
}
