.App {
  width: 100%;
}

body {
  overflow-x: hidden;
  background: black;
  background-image: url("../public/pictures/stars.png");
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

section {
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* mix-blend-mode: screen; */
  transition: all linear;
  z-index: 9000;
}

.text-to-move {
  position: absolute;
  top: 500px;
  right: -100%;
  color: #0f6938;
  white-space: nowrap;
  font-size: 6vw;
  z-index: 9;
  transition: all linear;
  bottom: 0;
}

@media (max-width: 768px) {
  section {
    padding: 70px;
  }
  .pages-star {
    display: none;
  }
  .text-to-move {
    display: none;
  }
}

.pages-star {
  position: fixed;
  scale: 0.5;
  top: 0;
}

@media (max-width: 526px) {
  section {
    padding: 30px;
  }
}
