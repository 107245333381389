.services {
  margin: 0;
  padding: 0px 100px;
}

.services h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #0f6938;
  text-align: center;
}

.form-wrapper {
  padding-bottom: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-wrapper h2 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: #0f6938;
  text-align: center;
}

@media (max-width: 525px) {
  .services {
    padding: 0px;
  }
  .services h2 {
    white-space: nowrap;
    font-size: 3em;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
}
