@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 70px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1000;
  max-height: 100px;
}

header .logo {
  color: #0f6938;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

header ul li {
  list-style: none;
  margin: 0 20px;
  text-decoration: none;
}

header ul li a {
  color: #0f6938;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 20px;
  cursor: pointer;
}
header ul li a:hover,
header ul li a.active {
  background: #0f6938;
  color: #fff;
}

/* .dropdown {
  opacity: 0;
  position: absolute;
  right: 285px;
  flex-direction: column;
  gap: 2px;
  z-index: 2;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  transform: translateX(-40%);
  transition: opacity 0.15s ease-out;
}

.dropdown-item {
  padding: 2px;
  border: 1px solid #0f6938;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dropdown p {
  text-transform: capitalize;
}

.has-dropdown:focus-within,
.has-dropdown:hover .dropdown {
  opacity: 1;
  pointer-events: auto;
} */

@media (max-width: 1024px) {
  header {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  header {
    padding-right: 20px;
    padding-left: 20px;
  }
  header ul li a {
    font-size: 0.9em;
  }
}

@media (max-width: 525px) {
  header ul li {
    margin: 0px;
  }
  header ul li a {
    font-size: 0.8em;
  }
}

.link {
  text-decoration: none;
}
